import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { SignUp, Signin, TokenData, User, VerifyToken } from '../models/auth.model';
import { ApiResponse } from 'libs/auths/src/lib/models/api-response.model';
import {SIGNIN_API_URL, SIGNUP_API_URL } from '../constant/auth.constants';
import { HttpRoutingService } from 'libs/common/src/lib/service/http-routing.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HeaderService } from 'libs/auths/src/lib/service/header.service';
/**
 * Service responsible for handling authentication-related operations such as signing in and signing up.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  url = environment.assetsUrl;

  /**
   * BehaviorSubject to hold the current user details or null.
   * @type {BehaviorSubject<User[] | null>}
   */
  public user$ = new BehaviorSubject<User[] | null>(null)
  /**
   * BehaviorSubject to hold the current login role details or null.
   * @type {BehaviorSubject<TokenData | null>}
   */
  public role$ = new BehaviorSubject<TokenData|null>(null)
  /**
   * Holds the region information as a BehaviorSubject.
   * The default region is set to 'us-west-2'.
   * @type {BehaviorSubject<string>}
   */
  public region$!: BehaviorSubject<string>;
  /**
   * Constructor to inject the required services.
   * @param {HttpRoutingService} httpService - Service to handle HTTP requests.
   */
  constructor(
    private httpService: HttpRoutingService,
    private httpClient: HttpClient,
    private headerService: HeaderService,
    private httpRouting: HttpRoutingService
  ) {
      if(localStorage.getItem('region') === 'ap-south-1' || localStorage.getItem('region') === 'us-west-2') {
        const storedRegion = localStorage.getItem('region') || 'ap-south-1';
        this.region$ = new BehaviorSubject<string>(storedRegion);
        localStorage.setItem('region', this.region$?.value)
      }
      else {
        localStorage.setItem('region', 'ap-south-1')
        const storedRegion = 'ap-south-1'
        this.region$ = new BehaviorSubject<string>(storedRegion);
      }
  }
  /**
   * Signs in a user with the provided sign-in data.
   * @param {Signin} signinData - The data required for signing in, including email and password.
   * @returns {Observable<TokenData>} - An observable containing the token data upon successful sign-in.
   */
  signIn(signinData: Signin): Observable<TokenData> {
    return this.httpService
      .postRequest<ApiResponse<TokenData>, Signin>(SIGNIN_API_URL, signinData)
      .pipe(
        map((res: ApiResponse<TokenData>) => {
          // sessionStorage.setItem('currentUserToken', JSON.stringify({ token: res['token'], refreshToken: res['refreshToken'] }))
          const token = res?.response?.token ?? ''
          localStorage.setItem('jwt_token', token);
          return res?.response;
        })
      );
  }
  /**
   * Signs up a new user with the provided sign-up data.
   * @param {SignUp} signupData - The data required for signing up, including first name, last name, email, and password.
   * @returns {Observable<any>} - An observable containing the server's response upon successful sign-up.
   */
  signUp(signupData: SignUp): Observable<any> {
    return this.httpService
      .postRequest<ApiResponse<any>, SignUp>(SIGNUP_API_URL, signupData)
      .pipe(
        map((res: ApiResponse<any>) => {
          return res.response;
        })
      );
  }
  /*
   * Method which is used to get dashboard details.
   */
  getDashboardDetails() {
    // return this.httpClient.get(this.url + 'dashboarddetails.json');
    return this.httpRouting.getRequest('microservice/mail/report/dashboard');
  }
  /**
   * Logs out the current user by removing the JWT token from local storage and clearing the authorization header.
   * @returns {boolean} - Returns true indicating the logout operation was successful.
   */
  logout(): boolean {
    // localStorage.removeItem('currentUserToken');
    localStorage.removeItem('jwt_token');
    this.headerService.clearHeaders('default', 'Authorization');
    return true;
  }
  /**
   * Retrieves the JWT token from local storage.
   * @returns {string} - The JWT token, or an empty string if the token is not found.
   */
  getToken(): string {
    const token = localStorage.getItem('jwt_token') || '';
    return token;
    // const currentUser = JSON.parse(sessionStorage.getItem('currentUserToken') || '');
    // if (currentUser) {
    //   token = currentUser.token;
    // }
    // return token;
  }

  /**
   * Checks if the user is authenticated by verifying the presence of a JWT token.
   * @returns {boolean} - Returns true if a JWT token is found, indicating the user is authenticated; otherwise, returns false.
   */
  isAuthenticated(): boolean {
    if (this.getToken()) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Sets the region information in the HTTP headers for subsequent requests.
   * @returns {void}
   */
  setRegionInHeader(): void {
    this.region$.subscribe({
      next: (res: string) => {
        this.headerService.setheader('default', 'Region', res);
      },
    });
  }
  /**
   * Fetches a new refresh token from the authentication service.
   * @returns {Observable<any>} - An observable containing the new refresh token or an error.
   */
  getRefreshToken(): Observable<any> {
    const currentUser = JSON.parse(
      sessionStorage.getItem('currentUserToken') || ''
    );
    const refreshToken = currentUser ? currentUser.refreshToken : null;
    return this.httpRouting.postRequest('refreshToken', { refreshToken });
  }
  forgotPassword(email:any){
  return this.httpRouting.postRequest('microservice/mail/recipientdetails/forgot',email)
  }
  getUserToken(userToken:any):Observable<VerifyToken>{
    // const token = new HttpParams().set('token', userToken);
    return this.httpRouting.postRequest('microservice/mail/recipientdetails/verify', userToken);
  }
  passwordSet(passwordDetails:any){
    return this.httpRouting.putRequest('microservice/mail/recipientdetails/set',passwordDetails)
  }
  verifyUser(password:any): Observable<VerifyToken>{
    return this.httpRouting.postRequest('microservice/mail/recipientdetails/password/verify',password);
  }
  signUpNew(signUpValues:any){
    return this.httpRouting.postRequest('microservice/mail/auth/signup/new',signUpValues)
  }
 /** Function to check if a given email ID exists in the system.
* Parameters:
* - emailId: The email ID to check (should ideally have a defined type as 'string').
* Makes a GET request to the specified endpoint with the email ID as a parameter.
* Returns an observable that emits the response if successful, or null in case of an error.
*/
  checkEmailExist(emailId: string) {
    return this.httpRouting.getRequest('microservice/mail/activity/check', { emailId }).pipe(
      map((response) => {
        return response
      }),
      catchError(() => of(null)) 
    );
  }
   /** Function to check if a specific language code exists in the system.
* Parameters:
* - languageCode: The language code to verify (should ideally have a defined type as 'string').
* Makes a GET request to the endpoint with the language code as part of the URL.
* Returns an observable that emits the response if successful, or null in case of an error.
*/
  checkLanguageCodeExist(languageCode: string) {
    return this.httpRouting.getRequest(`microservice/mail/languages/${languageCode}`).pipe(
      map((response) => {
        return response
      }),
      catchError(() => of(null)) 
    );
  }
  checkCategoryCodeExist(categoryCode:string){
    return this.httpRouting.getRequest('microservice/mail/category/validation',{categoryCode:categoryCode}).pipe(
      map((response) => {
        return response
      }),
      catchError(() => of(null)) 
    )
  }
  checkApplicationNameExist(applicationName: string) {
    return this.httpRouting.getRequest('microservice/mail/application/validation', {applicationName:applicationName}).pipe(
      map((response) => response),
      catchError(() => of(null))
    );
  }
  checkCustomerExist(applicationId: any,customerId:any) {
    const data={
      applicationId:applicationId,
      customerId:customerId
    }
    return this.httpRouting.getRequest('microservice/mail/recipientdetails/validation', data).pipe(
      map((response) => response),
      catchError(() => of(null))
    );
  }
}
