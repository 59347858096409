import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'libs/auths/src/lib/service/header.service';
import { environment } from 'src/environments/environment';
import { SIGNIN_API_URL } from '../../libs/auths/src/lib/constant/auth.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'zen-mail';
  APIUrl = environment.APIUrl;
  constructor( private headerService: HeaderService) { }
  ngOnInit(): void {
    this.headerService.setheader(this.APIUrl + SIGNIN_API_URL, 'content-type', 'application/json');
  }
}
