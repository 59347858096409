import { Component, Inject, inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { SNACKBAR_DETAILS } from '../../constants/common-snackbar-data.constants';
import { SnackbarDetails } from '../../models/common-snackbar-data.model';
import { CoreModule } from 'libs/core/src/lib/core.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-common-snackbar',
  templateUrl: './common-snackbar.component.html',
  standalone:true,
  imports:[CoreModule,CommonModule],
  styleUrls: ['./common-snackbar.component.scss']
})
export class CommonSnackbarComponent {
  /**
   * varibale which holds the snackbar reference
   */
  snackBarRef = inject(MatSnackBarRef)
  /**
   * @type {Array<SnackbarDetails>}
   * Assigning the constant SNACKBAR_DETAILS to the snackbarDetails variable.
   */
  snackbarDetails: Array<SnackbarDetails> = SNACKBAR_DETAILS;
  
  /**
   * Component constructor which is used to inject the required services.
   * @param data The data which is used for displaying the message in snackbar.
   */
   constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
   /**
    * Angular Life Cycle hook
    */
   ngOnInit() {    
    const data = this.snackbarDetails?.find(v => v?.type === this.data?.actionType) || this.snackbarDetails?.find(v => v?.type === "default");
    if (data && data.iconColor){
      this.data.icon = data?.icon;
      this.data.iconBackgroundColor = data?.iconColor;
    }
  }

  closeSnackBar() {
    this.snackBarRef.dismiss();
  }

}
