<div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px">  
  <div fxLayout="row" fxLayoutGap="15px"> 
    <div [ngStyle]="{'background':data.iconBackgroundColor}" class="prefix-icon-background" fxLayoutAlign="center center">
      <mat-icon data-control="snackbar-icon" class="snackbar-action-icon">{{ data.icon }}</mat-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px" class="snackbar-heading-and-button-container" [ngStyle]="{'width':'100%'}">
      <div *ngIf="data.heading" data-control="snackbar-heading" class="snackar-heading">{{ data.heading }}</div>
      <div *ngIf="data.message" class="snackbar-message" [ngStyle]="data.heading ? {} : {'color': '#101010', 'font-size': '15px', 'font-weight': '500'}">
        <span data-control="snackbar-message">{{ data.message }}</span>
      </div>
    </div>
  </div>
  <button mat-icon-button (click)="closeSnackBar()">
    <mat-icon data-control="snackbar-close-button" class="snackbar-icon">clear</mat-icon>
  </button>
</div>