/**
 * Guard which is used to check whether the changes are saved when navigate to other page.
 */
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CommonDialogService } from './common-dialog.service';
import { DIALOG_TYPE, DIALOG_HEADER, CONFIRM_MESSAGES, BUTTON_TEXT } from '../constants/common-dialog-data.constants';
import { DialogType, DialogHeader, ButtonText } from '../models/common-dialog-data.model';
/**
 * Abstract class method which is used to declare the canDeactivate method.
 */
export abstract class FormCanDeactivate {
  /**
   * abstract function,It can be implemented in component. Observable<boolean>|
   * @return {boolean}
   */
  abstract canDeactivate(): boolean;
}
/**
 * Guard which is used to check whether the changes are saved when navigate to other page
 */
@Injectable()
export class CanDeactivateGuard implements CanDeactivate<FormCanDeactivate> {
  /**
   * @type {DialogType}
   * Assigning the constant DIALOG_TYPE to the dialogType variable.
   */
  dialogType: DialogType = DIALOG_TYPE;
  /**
   * @type {DialogHeader}
   * Assigning the constant DIALOG_HEADER to the dialogHeader variable.
   */
  dialogHeader: DialogHeader = DIALOG_HEADER;
  /**
   * Assigning the constant CONFIRM_MESSAGES to the dialogConfirmMessages variable.
   */
  message = CONFIRM_MESSAGES;
  /**
   * Assigning the constant BUTTON_TEXT to the buttonText variable
   * @type {ButtonText}
   */
  buttonText: ButtonText = BUTTON_TEXT
  /**
   * Component constructor which is used to inject the required services.
   * @param dialogService To access the functions inside the DialogService.
   */
  constructor(private dialogService: CommonDialogService) {
  }
  /**
   * Method which is used to dispaly dialog when navigate to another route without saving form data.
   * @param component To get the FormCanDeactivate component.
   */
  canDeactivate(component: FormCanDeactivate): Observable<boolean> | boolean {
    // To check whether the component have unsaved changes.
    if (component && typeof component.canDeactivate === 'function'&& !component.canDeactivate()) {
      // If component have unsaved changes then create observable to return the selected value.
      return new Observable((observer: Observer<boolean>) => {
        const dialogRef: any = this.dialogService.openDialog({
          header: this.dialogHeader.confirmation,
          message: this.message.unsavedChanges,
          actionType: this.dialogType.confirmation,
          button: { left: this.buttonText.stay, right: this.buttonText.leaveText },
          disableClose: true
        });
        dialogRef.pipe(filter((result: boolean) => {
          return result;
        })).subscribe((res: any) => {
          observer.next(true);
          observer.complete();
        });
      }
      );
    } else {
      return true;
    }
  }
}
