import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'libs/auths/src/lib/service/auth.guard';
import { logoutGuard } from 'libs/auths/src/lib/service/logout.guard';
import { CanDeactivateGuard } from 'libs/common/src/lib/service/can-deactivate-guard.service';
import { SideMenuBarComponent } from 'libs/auths/src/public-api';

// Define child route arrays for each section

const mailsChildren: Routes = [
  { 
    path: 'inbox', 
    loadComponent: () => import('../app/zen-mails/inbox/components/inbox/inbox.component').then(component => component.InboxComponent) 
  },
  { 
    path: 'sent', 
    loadComponent: () => import('../app/zen-mails/inbox/components/inbox/inbox.component').then(component => component.InboxComponent) 
  },
  { 
    path: 'starred', 
    loadComponent: () => import('../app/zen-mails/inbox/components/inbox/inbox.component').then(component => component.InboxComponent) 
  },
  { 
    path: 'trash', 
    loadComponent: () => import('../app/zen-mails/inbox/components/inbox/inbox.component').then(component => component.InboxComponent) 
  },
  { 
    path: 'spam', 
    loadComponent: () => import('../app/zen-mails/inbox/components/inbox/inbox.component').then(component => component.InboxComponent) 
  },
  { 
    path: 'draft', 
    loadComponent: () => import('../app/zen-mails/inbox/components/inbox/inbox.component').then(component => component.InboxComponent) 
  },
  { 
    path: 'viewMail/:data/:id', 
    loadComponent: () => import('../app/zen-mails/inbox/components/view-mail/view-mail.component').then(component => component.ViewMailComponent) 
  },
];

const ZenMailChildren: Routes = [
  { path: 'dashboard', loadComponent: () => import('libs/auths/src/lib/components/dashboard/dashboard.component').then(component => component.DashboardComponent) },
  { path: 'profile', loadComponent: () => import('libs/auths/src/lib/components/view-profile/view-profile.component').then(component => component.ViewProfileComponent) },
  {
    path: 'users',
    children: [
      { path: 'user-lists', 
        loadComponent: () => import('../app/zen-mail/Users/components/user-list/user-list.component').then(component => component.UserListComponent) },
    ]
  },
  {
    path: 'applications',
    children: [
      { path: 'application-lists', 
        loadComponent: () => import('../app/zen-mail/Applications/components/applications/applications.component').then(component => component.ApplicationsComponent)
      },
      { 
        path: 'add-edit-view-application/:action', 
        loadComponent: () => import('../app/zen-mail/Applications/components/apllication-forms/apllication-forms.component').then(component => component.ApllicationFormsComponent), canDeactivate: [CanDeactivateGuard] 
      },
      {
         path: 'add-edit-view-application/:data/:action', 
         loadComponent: () => import('../app/zen-mail/Applications/components/apllication-forms/apllication-forms.component').then(component => component.ApllicationFormsComponent), canDeactivate: [CanDeactivateGuard] 
        },
    ]
  },
  {
    path:'customers',
    children:[
      { path: 'customer-lists', 
       loadComponent: () => import('../app/zen-mail/Customers/components/mail-customers-list/mail-customers-list.component').then(component => component.MailCustomersListComponent) 
      },
      { path: 'add-edit-view-customer/create', 
        loadComponent: () => import('../app/zen-mail/Customers/components/mail-add-edit-customer/mail-add-edit-customer.component').then(component => component.MailAddEditCustomerComponent), canDeactivate: [CanDeactivateGuard] 
      },
      { path: 'add-edit-view-customer/:action/:data', 
      loadComponent: () => import('../app/zen-mail/Customers/components/mail-add-edit-customer/mail-add-edit-customer.component').then(component => component.MailAddEditCustomerComponent), canDeactivate: [CanDeactivateGuard]
      },
      { path: 'recipients', 
        loadComponent: () => import('../app/zen-mail/Customers/components/mail-recipients-list/mail-recipients-list.component').then(component => component.MailRecipientsListComponent) 
      }
  ]
  },
  {
   path:'templates',
   children:[
    { path: 'category-lists', 
      loadComponent: () => import('../app/zen-mail/Categories/components/categories-list/categories-list.component').then(component => component.CategoriesListComponent) 
    },
    { path: 'add-edit-view-category/:action', 
      loadComponent: () => import('../app/zen-mail/Categories/components/add-edit-categories/add-edit-categories.component').then(component => component.AddEditCategoriesComponent), canDeactivate: [CanDeactivateGuard] 
    },
    { path: 'add-edit-view-category/:data/:id', 
      loadComponent: () => import('../app/zen-mail/Categories/components/add-edit-categories/add-edit-categories.component').then(component => component.AddEditCategoriesComponent), canDeactivate: [CanDeactivateGuard]
    },
    { path: 'template-lists', 
      loadComponent: () => import('../app/zen-mail/Templates/components/mail-templates-list/mail-templates-list.component').then(component => component.MailTemplatesListComponent) 
    },
    { path: 'add-edit-view-template/:action', 
      loadComponent: () => import('../app/zen-mail/Templates/components/mail-add-edit-template/mail-add-edit-template.component').then(component => component.MailAddEditTemplateComponent), canDeactivate: [CanDeactivateGuard] 
    },
    { path: 'add-edit-view-template/:data/:id/:customer', 
      loadComponent: () => import('../app/zen-mail/Templates/components/mail-add-edit-template/mail-add-edit-template.component').then(component => component.MailAddEditTemplateComponent), canDeactivate: [CanDeactivateGuard] 
    },
   ]
  },
  {
   path:'logs',
   children:[
    { path: 'log-lists', 
      loadComponent: () => import('../app/zen-mail/Logs/components/log-list/log-list.component').then(component => component.LogListComponent) 
    },
    { path: 'view-log/:id', 
      loadComponent: () => import('../app/zen-mail/Logs/components/view-mail-log/view-mail-log.component').then(component => component.ViewMailLogComponent) 
    },
   ]
  },
  {
  path:'reports',
  children:[
    { path: 'report-lists', 
      loadComponent: () => import('../app/zen-mail/Reports/components/report-list/report-list.component').then(component => component.ReportListComponent) 
    },
    { path: 'view-report/:id', 
      loadComponent: () => import('../app/zen-mail/Reports/components/view-report/view-report.component').then(component => component.ViewReportComponent)
    },
    ]
  },
  {
  path:'configurations',
  children:[
    { path: 'configuration-lists', 
      loadComponent: () => import('../app/zen-mail/Configurations/components/configuration-list/configuration-list.component').then(component => component.ConfigurationListComponent) 
    },
    { path: 'add-edit-view-configuration/:action', 
        loadComponent: () => import('../app/zen-mail/Configurations/components/add-edit-configuration/add-edit-configuration.component').then(component => component.AddEditConfigurationComponent), canDeactivate: [CanDeactivateGuard] 
    },
    { path: 'add-edit-view-configuration/:action/:data', 
      loadComponent: () => import('../app/zen-mail/Configurations/components/add-edit-configuration/add-edit-configuration.component').then(component => component.AddEditConfigurationComponent), canDeactivate: [CanDeactivateGuard] 
    }
  ]
  },
  {
  path:'languages',
  children:[
    { path: 'language-lists', 
      loadComponent: () => import('../app/zen-mail/Languages/components/language-list/language-list.component').then(component => component.LanguageListComponent) 
    },
  ]
  }
];

const ZenMarketingChildren: Routes = [
  {
    path:'customers',
    children:[
      { path: 'customer-lists', 
       loadComponent: () => import('../app/zen-marketing/Customers/components/marketing-customers-list/marketing-customers-list.component').then(component => component.MarketingCustomersListComponent) 
      },
      { path: 'add-edit-view-customer/create', 
        loadComponent: () => import('../app/zen-marketing/Customers/components/marketing-add-edit-customer/marketing-add-edit-customer.component').then(component => component.MarketingAddEditCustomerComponent), canDeactivate: [CanDeactivateGuard] 
      },
      { path: 'add-edit-view-customer/:action/:data', 
      loadComponent: () => import('../app/zen-marketing/Customers/components/marketing-add-edit-customer/marketing-add-edit-customer.component').then(component => component.MarketingAddEditCustomerComponent), canDeactivate: [CanDeactivateGuard]
      }
  ]
  },
  {
    path:'templates',
    children:[
      {
        path: 'template-lists',
        loadComponent: () =>import('../app/zen-marketing/Templates/components/marketing-templates-list/marketing-templates-list.component').then(component => component.MarketingTemplatesListComponent),
      },
      {
        path: 'add-edit-view-template',
        loadComponent: () =>import('../app/zen-marketing/Templates/components/marketing-add-edit-template/marketing-add-edit-template.component').then(component => component.MarketingAddEditTemplateComponent), canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'add-edit-view-template/:data/:id/:customer',
        loadComponent: () =>import('../app/zen-marketing/Templates/components/marketing-add-edit-template/marketing-add-edit-template.component').then(component => component.MarketingAddEditTemplateComponent),canDeactivate: [CanDeactivateGuard],
      }
    ]
  },
  {
   path:'campaigns',
   children:[
    {
      path: 'campaign-lists',
      loadComponent: () =>import('../app/zen-marketing/Campaigns/components/campaign-list/campaign-list.component').then(component => component.CampaignListComponent),
    },
    {
      path: 'add-edit-view-campaign/:data',
      loadComponent: () =>import('../app/zen-marketing/Campaigns/components/add-view-campaign/add-view-campaign.component').then(component => component.AddViewCampaignComponent),canDeactivate: [CanDeactivateGuard],
    },
    {
      path: 'add-edit-view-campaign/:data/:id',
      loadComponent: () =>import('../app/zen-marketing/Campaigns/components/add-view-campaign/add-view-campaign.component').then(component => component.AddViewCampaignComponent),canDeactivate: [CanDeactivateGuard],
    },
   ]
  },
  {
   path:'tracking',
   children:[
    {
      path: 'tracking-lists',
      loadComponent: () =>import('../app/zen-marketing/Trackings/components/tracking-list/tracking-list.component').then(component => component.TrackingListComponent),
    },
    {
      path: 'view-tracking/:data/:id',
      loadComponent: () =>import('../app/zen-marketing/Trackings/components/view-tracking/view-tracking.component').then(component => component.ViewTrackingComponent),
    }
   ]
  },
  {
    path: 'view-log/:id',
    loadComponent: () =>import('../app/zen-mail/Logs/components/view-mail-log/view-mail-log.component').then(component => component.ViewMailLogComponent),
  }
];

const routes: Routes = [
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: 'signin', loadComponent: () => import('libs/auths/src/lib/components/signin/signin.component').then(component => component.SigninComponent), canActivate: [logoutGuard] },
  { path: 'set-password', loadComponent: () => import('libs/auths/src/lib/components/registor/registor.component').then(component => component.RegistorComponent) },
  { path: 'forgot-password', loadComponent: () => import('libs/auths/src/lib/components/registor/registor.component').then(component => component.RegistorComponent) },
  { path: 'change-password', loadComponent: () => import('libs/auths/src/lib/components/registor/registor.component').then(component => component.RegistorComponent) },
  {
    path: 'app',
    canActivate: [authGuard],
    loadComponent: () => import('libs/auths/src/lib/components/side-menu-bar/side-menu-bar.component').then(module => module.SideMenuBarComponent),
    children: [
      {
        path:'mails',
        children:mailsChildren
      },
      {
        path: 'zen-mail',
        children: ZenMailChildren 
      },
      {
        path: 'zen-marketing',
        children: ZenMarketingChildren 
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
