import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatDividerModule } from '@angular/material/divider';
import { ZenFlexLayoutModule } from 'zen-flex-layout';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';


@NgModule({
  declarations: [
  ],
  imports: [
     CommonModule,
     MatRadioModule,
     MatSliderModule,
     MatFormFieldModule,
     MatInputModule,
     MatTabsModule,
     MatMenuModule,
     MatSelectModule,
     MatAutocompleteModule,
     MatDatepickerModule,
     MatIconModule,
     MatListModule,
     MatTableModule,
     MatCardModule,
     MatDialogModule,
     MatSidenavModule,
     MatPaginatorModule,
     MatExpansionModule,
     MatGridListModule,
     MatProgressSpinnerModule,
     MatToolbarModule,
     MatChipsModule,
     MatTooltipModule,
     MatButtonToggleModule,
     MatStepperModule,
     MatBottomSheetModule,
     MatProgressBarModule,
     ReactiveFormsModule,
     FormsModule,
     MatSnackBarModule,
     MatBadgeModule,
     MatButtonModule,
     MatSortModule,
     MatNativeDateModule,
     MatSlideToggleModule,
     MatCheckboxModule,
     MatTreeModule,
     MatDividerModule,
     ZenFlexLayoutModule,
     ClipboardModule,
     NgxMaterialTimepickerModule,
   ],
   exports: [
     MatRadioModule,
     MatSliderModule,
     MatFormFieldModule,
     MatInputModule,
     MatTabsModule,
     MatMenuModule,
     MatSelectModule,
     MatAutocompleteModule,
     MatDatepickerModule,
     MatIconModule,
     MatListModule,
     MatTableModule,
     MatCardModule,
     MatDialogModule,
     MatSidenavModule,
     MatPaginatorModule,
     MatExpansionModule,
     MatGridListModule,
     MatProgressSpinnerModule,
     MatToolbarModule,
     MatChipsModule,
     MatTooltipModule,
     MatButtonToggleModule,
     MatStepperModule,
     MatBottomSheetModule,
     MatProgressBarModule,
     ReactiveFormsModule,
     FormsModule,
     MatSnackBarModule,
     MatBadgeModule,
     MatButtonModule,
     MatSortModule,
     MatNativeDateModule,
     MatSlideToggleModule,
     MatCheckboxModule,
     MatTreeModule,
     MatDividerModule,
     ZenFlexLayoutModule,
     ClipboardModule,
     NgxMaterialTimepickerModule
   ]
})
export class CoreModule { }
