import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { HeaderService } from './header.service';
import { AuthService } from 'libs/auths/src/lib/service/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService  implements HttpInterceptor{

  constructor(private headerService : HeaderService, private authService :AuthService,private router: Router) { }
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isRefreshingToken!: boolean;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.setHeaders(request)).pipe(catchError((err) => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.authService.logout();
        this.router.navigate(['/signin'])
        // return this.handleError(request, next);
      }
      return throwError(err);
  }));
  }
  // handleError(request: HttpRequest<any>, next: HttpHandler) {
  //   if (!this.isRefreshingToken) {
  //     this.isRefreshingToken = true;
  //     this.refreshTokenSubject.next(null);
  //     return this.authService
  //       .getRefreshToken().pipe(switchMap((token: any) => {
  //         this.isRefreshingToken = false;
  //         this.refreshTokenSubject.next(token['aceessToken']);
  //         this.headerService.setheader('default', 'Authorization', token['accessToken']);
  //         sessionStorage.setItem('token', token['accessToken']);
  //         return next.handle(this.setHeaders(request));
  //       }), catchError((err: any) => {
  //         this.isRefreshingToken = false;
  //         this.authService.logout();
  //         this.router.navigate(['/signin']);
  //         return throwError(err);
  //       }));
  //   }
  //   else {
  //     return this.refreshTokenSubject.pipe(filter(token => token !== null),
  //       take(1), switchMap(jwtToken => next.handle(this.setHeaders(request))));
  //   }

  // }
  /**
   * Sets the headers for an outgoing HTTP request.
   * @param {HttpRequest<any>} request - The outgoing HTTP request for which headers need to be set.
   * @returns {HttpRequest<any>} - The modified HTTP request with the necessary headers set.
   */
  setHeaders(request: HttpRequest<any>) {
    const headers = this.headerService.getHeaders(request.url);
    return headers
      ? request.clone({
          setHeaders: headers,
        })
      : request;
  }
}
