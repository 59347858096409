import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'libs/auths/src/lib/service/auth.service';
import { HeaderService } from 'libs/auths/src/lib/service/header.service';
import { BehaviorSubject } from 'rxjs';
import { HttpRoutingService } from 'libs/common/src/lib/service/http-routing.service';
import { GET_USER_API_URL } from '../constant/auth.constants';
import { CommonSnackbarService } from 'libs/common/src/lib/service/common-snackbar.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const headerService = inject(HeaderService);
  const router = inject(Router);

  if (authService.isAuthenticated()) {
    const token = authService.getToken();
    if (token) {
      headerService.setheader('default', 'Authorization', token);
      headerService.setheader('default', 'Content-Type', 'application/json');
      authService.setRegionInHeader();
      
      try {
        await getUserDetails();
      } catch (error) {
        router.navigate(['/signin']);
        return false;
      }
    }
    return true;
  } else {
    router.navigate(['/signin']);
    return false;
  }
};

/**
 * Fetches user details and updates the user$ BehaviorSubject.
 */
const getUserDetails = (): Promise<void> => {
  const authService = inject(AuthService);
  const httpService = inject(HttpRoutingService);
  const snackbar = inject(CommonSnackbarService);

  return new Promise((resolve, reject) => {
    httpService.getRequest<any>(GET_USER_API_URL).subscribe(
      (res) => {
        authService.user$.next(res.customer);
        authService.role$.next({ roleId: res.customer.roleId });
        resolve();
      },
      (err) => {
        if (err) {
          snackbar.OpenSnackBar({
            message: 'Something went wrong in getting user details',
            heading: 'Failed',
            actionType: 'failure',
            duration: 2,
          });
        }
        reject(err);
      }
    );
  });
};

